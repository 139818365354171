import { render, staticRenderFns } from "./Merchant.vue?vue&type=template&id=6806c67c&scoped=true&"
import script from "./Merchant.vue?vue&type=script&lang=ts&"
export * from "./Merchant.vue?vue&type=script&lang=ts&"
import style0 from "./Merchant.vue?vue&type=style&index=0&id=6806c67c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6806c67c",
  null
  
)

export default component.exports