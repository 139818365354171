import gql from "graphql-tag";

export const merchantBasic = gql`
  fragment MerchantBasic on App {
    activePagePhotoUrl
    uuid
    name
    ownerFullName
    activePage {
      name
      fbPageId
      active
    }
    plan
    launchedAt
    accumulatedConvenienceFee
    accumulatedCompletedOrders
    subscribedEnterprisePlan {
      uuid
      name
    }
    currentAppEnterprisePlan {
      subscriptionStartAt
      subscriptionEndAt
      subscriptionMonths
    }
    serviceBundle {
      name
    }
    status
    active
    activeSalesChannel
    autoApproveBilling
  }
`;

const merchantFull = gql`
  fragment MerchantFull on App {
    ...MerchantBasic
    appOwnerEmail
    currentAppEnterprisePlan {
      msmeStartAt
      msmeEndAt
    }
    addressable
    inquiry
  }
  ${merchantBasic}
`;

export const Merchant = {
  fragments: { merchantFull, merchantBasic },
};
